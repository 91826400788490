<template>
  <layout-horizontal>

    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">

      <b-toast id="example-toast" dismissible>
        <template #toast-title>
          <div class="d-flex flex-grow-1 align-items-center mr-1">
            <b-img :src="require('@/assets/images/logo/logo.png')" class="mr-1" height="18" width="25"
              alt="Toast image" />
            <strong class="mr-auto">Vue Admin</strong>
            <small class="text-muted">11 mins ago</small>
          </div>
        </template>
        <span>Hello, world! This is a toast message. Hope you're doing well.. :)</span>
      </b-toast>


      <app-navbar-horizontal-layout-brand />
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
  </layout-horizontal>
</template>

<script>
import LayoutHorizontal from '@core/layouts/layout-horizontal/LayoutHorizontal.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import AppNavbarHorizontalLayoutBrand from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayoutBrand.vue'
import Navbar from '../components/Navbar.vue'
import {
  isUserLoggedIn
} from "@/auth/utils";
import {
  initialAbility
} from "@/libs/acl/config";
import { gerRole } from "@/auth/utils";
import { eventBus } from '@/main';
// import SseService from '@/notification/SseService'; // Adjust the path based on your project structure

export default {
  metaInfo: {
    script: [
      {
        vmid: 'clarity',
        innerHTML: `(function(c,l,a,r,i,t,y){
                     c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                     t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                     y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                    })(window, document, "clarity", "script", "md11s7o84c");`
      }
    ],
    __dangerouslyDisableSanitizersByTagID: {
      clarity: ['innerHTML']
    }
  },
  components: {
    LayoutHorizontal,
    // AppCustomizer,
    Navbar,
    AppNavbarHorizontalLayoutBrand,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
      clarityScriptLoaded: false,
    }
  },
  created() {
    // SseService.connect((notification) => {
    //   console.log("notiff : ", notification);
    //   this.$bvToast.show('example-toast');
    // });
    // // Listen for the custom event
    eventBus.$on('show-sweetalert-error', error => {
      // Handle the error here (e.g., display an error message)
      console.error('Custom Event Error:', error);
      if (error.status != 422)
        this.showErrorMessage(error)
    });
  },
  async mounted() {
    await this.loadClarityScript();
    this.$store.dispatch('app-baskets/FILL_BASKET_HEADERS');
    this.$store.dispatch('app-favorites/FILL_FAVORITES');
    if (!isUserLoggedIn()) {
      this.logout();
    } else {
      switch (gerRole()) {
        case "ROLE_SUPERVISOR":
          this.$store.dispatch('app-items/FETCH_ITEMS_FROM_BACKEND_ADMIN').catch(() => this.$router.push({ name: 'error' }));
          break;

        case "ROLE_ADMIN":
          this.$store.dispatch('app-items/FETCH_ITEMS_FROM_BACKEND').catch(() => this.$router.push({ name: 'error' }));
          this.$store.dispatch('app-items/FETCH_BLOQUED_ITEMS');
          break;

        case "ROLE_CUSTOMER":
          await this.$store.dispatch('app-userdata/INIT_COMPANIES').then(() => {
            this.$store.dispatch('app-userdata/INIT_USER_DATA').catch(() => this.$router.push({ name: 'error' }));
            this.$store.dispatch('app-items/FETCH_ITEMS_FROM_BACKEND').catch(() => this.$router.push({ name: 'error' }));
            this.$store.dispatch('app-items/COUNT_ORDER_LINES');
            this.$store.dispatch('app-items/FETCH_BLOQUED_ITEMS');
          }).catch(() => this.$router.push({ name: 'not-affected' }));
          break;

        case "ROLE_EDITOR":
          await this.$store.dispatch('app-userdata/INIT_COMPANIES').then(() => {
            this.$store.dispatch('app-userdata/INIT_USER_DATA').catch(() => this.$router.push({ name: 'error' }));
            this.$store.dispatch('app-items/FETCH_ITEMS_FROM_BACKEND').catch(() => this.$router.push({ name: 'error' }));
            this.$store.dispatch('app-items/COUNT_ORDER_LINES');
            this.$store.dispatch('app-items/FETCH_BLOQUED_ITEMS');
          }).catch(() => this.$router.push({ name: 'not-affected' }));
          break;

        case "ROLE_SUPPLIER":
          await this.$store.dispatch('app-userdata/INIT_COMPANIES').then(() => {
            this.$store.dispatch('app-userdata/INIT_USER_DATA').catch(() => this.$router.push({ name: 'error' }));
          }).catch(() => this.$router.push({ name: 'not-affected' }));
          break;
      }
    }
  },
  methods: {
    loadClarityScript() {
      return new Promise((resolve, reject) => {
        if (window.clarity) {
          this.clarityScriptLoaded = true;
          clarity("set", "flight", "testflight1");
          return resolve();
        }
        const script = document.createElement('script');
        script.src = 'https://www.clarity.ms/tag/md11s7o84c';
        script.async = true;
        script.onload = () => {
          this.clarityScriptLoaded = true;
          clarity("set", "flight", "testflight1");
          resolve();
        };
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
    identifyUser() {
      if (window.clarity) {
        const userData = this.$store.state["app-userdata"].AppActiveUser;
        if (userData) {
          const userId = userData.username.toString();
          const userName = userData.lastName ? `${userData.firstName} ${userData.lastName}` : userData.firstName;
          console.log('Identifying user with ID:', userId, 'and name:', userName);
          // window.clarity('identify', userId, { name: userName });
          // clarity("set", "UserId", userId);
          // clarity("identify", userId, { name: userName });
          // console.log(clarity("set", "UserId", userId));
          // console.log(clarity("identify", userId, { name: userName }));
          console.log(this.clarityScriptLoaded);
          clarity("set", "flight", "testflight1");
          clarity('set', "userId", userId);
        } else {
          console.warn('User data is not available yet.');
        }
      } else {
        console.error('Clarity is not loaded');
      }
    },
    showErrorMessage(error) {
      if (error.data.message) {
        this.$swal({
          title: "Une erreur inattendue s'est manifestée.",
          text: error.data.message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
      else if (error.data.error) {
        this.$swal({
          title: "Une erreur inattendue s'est manifestée.",
          text: error.data.error,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        this.$swal({
          title: "Une erreur inattendue s'est manifestée.",
          text: error.data,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },
    logout() {
      this.$store.dispatch('app-userdata/logout').catch(error => console.log(error.response)).finally(() => {
        this.$ability.update(initialAbility)
        this.$router.push({ name: 'auth-login' })
        this.$forceUpdate()
      })
    }
  },
  computed: {
    userData() {
      return this.$store.state["app-userdata"].AppActiveUser
    },
  },
  watch: {
    // userData() {
    //   this.identifyUser();
    // }
  }
}
</script>
